const newsList = document.querySelector('.js_newsList');
const newsListClone = newsList.cloneNode(true);

for (const el of newsList.querySelectorAll('.newsList__item:nth-child(even)')) {
	el.remove();
}

for (const el of newsListClone.querySelectorAll('.newsList__item:nth-child(odd)')) {
	el.remove();
}

newsList.insertAdjacentElement('afterend', newsListClone);

// const prlxItems = document.querySelectorAll('.news__listItem:nth-child(odd)');
new simpleParallax(newsList, {
	scale: 1.05,
	delay: .6,
	overflow: true,
	transition: 'cubic-bezier(0,0,0,1)'
});

new simpleParallax(newsListClone, {
	scale: 0.95,
	delay: 1,
	overflow: true,
	transition: 'cubic-bezier(0,0,0,1)'
});
